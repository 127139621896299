import React from "react";
import Navigation from "../navigation/navigation";
import Footer from "../footer/footer";
import "./ToS-PrivacyPolicy.css";

export default class TermsOfUse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Terms Of Service",
      subtitle: "1. Terms",
      content: [
        `By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, as well as all applicable laws and regulations, and you agree that you are responsible for compliance with any applicable laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trademark law.`,
        `2. The use of the Internet or any form on this website as means of contacting The Cook Law Firm or any of its individual attorneys does not establish an attorney‐client relationship. Whether you are a new or existing client, The Cook Law Firm cannot represent you on any new matter until the firm has made a determination that there is no conflict of interest and that it is willing and otherwise able to accept the new engagement. Until that happens, do not send the firm any information or documents that you consider private or confidential.`,
        `3. The Cook Law Firm has placed information on this website as a service to the general public. Use of this website does not in any manner constitute an attorney‐client relationship between The Cook Law Firm and the user. Although the information on this website is about legal issues, it is not intended as legal advice or as a substitute for the particularized advice of your own counsel. Anyone seeking specific legal advice or assistance should retain an attorney.`
      ],
      four: `4. Use License`,
      fourSubA: `a. Permission is granted to temporarily download one copy of the materials (information or software) on The Cook Law Firm’s web site for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:`,
      fourSubList: [
        `modify or copy the materials;`,
        `use the materials for any commercial purpose, or for any public display (commercial or non-commercial);`,
        `attempt to decompile or reverse engineer any software contained on The Cook Law Firm’s web site;`,
        `remove any copyright or other proprietary notations from the materials; or`,
        `transfer the materials to another person or “mirror” the materials on any other server.`
      ],
      fourSubB: `b. This license shall automatically terminate if you violate any of these restrictions, and may be terminated by The Cook Law Firm at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.`,
      fiveSub: [
        { content: `5. Disclaimer`, size: "big" },
        {
          content: `Materials on The Cook Law Firm’s web site are provided “as is.” The Cook Law Firm makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, The Cook Law Firm does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.`,
          size: "normal"
        },
        { content: `6. Limitations`, size: "big" },
        {
          content: `In no event shall The Cook Law Firm or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use materials on The Cook Law Firm’s Internet site, even if The Cook Law Firm or one of its authorized representatives has been notified orally or in writing of the possibility of such damage.`,
          size: "normal"
        },
        { content: `7. Revisions and Errata`, size: "big" },
        {
          content: `Materials appearing on The Cook Law Firm’s web site could include technical, typographical, or photographic errors. The Cook Law Firm does not warrant that any of the materials on its web site are accurate, complete, or current. The Cook Law Firm may make changes to materials contained on its web site at any time without notice. The Cook Law Firm does not, however, make any commitment to update the materials.`,
          size: "normal"
        },
        { content: `8. Links`, size: "big" },
        {
          content: `The Cook Law Firm has not reviewed all of the sites linked to its Internet web site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by The Cook Law Firm of the site. Use of any such linked web site is at the user’s own risk.`,
          size: "normal"
        },
        { content: `9. Site Terms of Use Modifications`, size: "big" },
        {
          content: `The Cook Law Firm may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use.`,
          size: "normal"
        },
        { content: `10. Governing Law`, size: "big" },
        {
          content: `Any claim relating to The Cook Law Firm’s web site shall be governed by the laws of the State of California, without regard to its conflict of law provisions.`,
          size: "normal"
        }
      ]
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Navigation />
        <div className="container push-top push-bottom">
          <div className="row">
            <div className="col-12 push-top">
              <h1 className="tos-pp-header">{this.state.title}</h1>
              <h3 className="tos-pp-subtitle">{this.state.subtitle}</h3>
              {this.state.content.map(each => (
                <p>{each}</p>
              ))}
              <h3 className="tos-pp-subtitle">{this.state.four}</h3>
              <p>{this.state.fourSubA}</p>
              <ul>
                {this.state.fourSubList.map(each => (
                  <li>{each}</li>
                ))}
              </ul>
              <p>{this.state.fourSubB}</p>
              <p>{this.state.fiveSub.content}</p>
              <p>
                {this.state.fiveSub.map(each => (
                  <p
                    className={
                      each.size === "big" ? "tos-pp-subtitle" : "tos-pp-normal"
                    }
                  >
                    {each.content}
                  </p>
                ))}
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
