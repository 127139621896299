import React from "react";
import TinySlider from "tiny-slider-react";
import BackgroundOne from "./homebackground/imageone.jpg";
import BackgroundTwo from "./homebackground/imagetwo.jpg";
import BackgroundThree from "./homebackground/imagethree.jpg";
import UnderstandingHeader from "./backgroundCopy/understanding.png";
import UnderstandingText from "./backgroundCopy/understandingtext.png";
import CommitmentHeader from "./backgroundCopy/commitment.png";
import CommitmentText from "./backgroundCopy/commitmenttext.png";
import ExcellenceHeader from "./backgroundCopy/excellence.png";
import ExcellenceText from "./backgroundCopy/excellencetext.png";
import "./tinySliderHome.css";

export default class tinySliderHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [
        {
          header: UnderstandingHeader,
          text: UnderstandingText,
          cssHeader: `understanding`,
          cssText: `understanding-text`
        },
        {
          header: CommitmentHeader,
          text: CommitmentText,
          cssHeader: `commitment`,
          cssText: `commitment-text`
        },
        {
          header: ExcellenceHeader,
          text: ExcellenceText,
          cssHeader: `excellence`,
          cssText: `excellence-text`
        }
      ]
    };
  }
  // https://github.com/ganlanyuan/tiny-slider/issues/9
  render() {
    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center"
    };

    const imgStyles = {
      width: "100%",
      maxHeight: "576px",
      objectFit: "cover"
    };

    const imgs = [BackgroundOne, BackgroundTwo, BackgroundThree];

    const loadingImage =
      "data:image/gif;base64,R0lGODlhAQABAPAAAMzMzAAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";

    //   "data:image/gif;base64,\
    // R0lGODlhAQABAPAAAMzMzAAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";

    const settings = {
      lazyload: true,
      nav: false,
      mouseDrag: true,
      loop: true,
      items: 1,
      gutter: 0,
      autoplay: true,
      speed: 1000,
      controls: false,
      autoplayButtonOutput: false,
      responsive: {
        420: {
          items: 1
        }
      }
    };

    return (
      <div style={styles}>
        <TinySlider settings={settings}>
          {imgs.map((el, index) => (
            <div key={index} style={{ position: "relative" }}>
              <img
                className={`tns-lazy-img`}
                src={loadingImage}
                data-src={el}
                alt={el.replace("/static/media/", "").split(".")[0]}
                style={imgStyles}
              />
              <div className={`image-text-wrapper-${index}`}>
                <img
                  alt={this.state.content[index]}
                  src={this.state.content[index].header}
                  className={`${
                    this.state.content[index].cssHeader
                  } image-overlay`}
                />
                <img
                  alt={this.state.content[index].cssText}
                  src={this.state.content[index].text}
                  className={`${
                    this.state.content[index].cssText
                  } image-overlay`}
                />
              </div>
            </div>
          ))}
        </TinySlider>
      </div>
    );
  }
}
