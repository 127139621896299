import React from "react";
import Navigation from "../navigation/navigation";
import TinySlider from "./tinySlider/tinySlider";
import imageOne from "./attorneyImages/pcook.jpg";
import imageTwo from "./attorneyImages/bbhirsch.jpg";
import imageThree from "./attorneyImages/bjwright.jpg";
import "./attorneys.css";
import { Link } from "react-router-dom";

export default class Attorneys extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [
        {
          name: `Philip E. Cook`,
          title: `Managing Attorney`,
          contact: `Direct: 213-988-6090`,
          email: `pcook@cooklawfirm.la`,
          img: imageOne,
          content: [
            `<div>Phil has tried cases before judges, juries, arbitrators and arbitration panels and argued appeals in both federal and state courts. Phil’s practice currently focuses on insurance recovery for policyholders and business-to-business disputes in a variety of contexts. His success in the courtroom covers the gamit of commercial litigation, including individual and class action cases involving contract disputes; corporate governance and securities and financial products claims; Business and Professions Code section 17200 claims and business torts; real estate, land use and environmental issues; product liability and toxic torts; antitrust and trade regulation; patent, trademark and copyright infringement; trade secrets; governmental claims and liabilities; freedom of speech and religious protection under the First Amendment; health care, construction defect, and wrongful termination, employment discrimination and executive compensation.</div>`,
            `Read More ...`
          ],

          personalLink: "/bio/philip_cook",
          other: [
            {
              type: `Education`,
              content: `University of California, Los Angeles (J.D. 1990; Editor, Law Review); Pacific Christian College (B.A. Psychology, 1979)`
            },
            {
              type: `Admitted:`,
              content: `California, all U.S. District Courts in California, U.S. Court of Appeals for Ninth Circuit, and pro hac vice in courts in Indiana, Oregon, North Carolina, Texas, Washington and Hawaii`
            },
            {
              type: `Clerkship:`,
              content: `<div className="content-type">Law Clerk to Judge Consuelo B. Marshall, United States District Court, Central District of California (1991-92)</div>`
            }
          ]
        },
        {
          name: `Bridget Byrnes Hirsch`,
          title: `Of Counsel`,
          contact: `Direct: 213-988-6080`,
          email: `bbhirsch@cooklawfirm.la`,
          img: imageTwo,
          content: [
            `<p>Bridget is an accomplished litigator, with a practice that has included insurance recovery, entertainment, intellectual property and commercial disputes. She started her career with two prominent law firms, first at Anderson Kill & Olick, P.C. in New York, then with Reed Smith LLP in Los Angeles, representing a wide array of companies and business interests. Bridget played a lead role in prosecuting a coverage claim against an insurance company that resulted in a half-billion dollar settlement for unpaid defense costs incurred in thousands of underlying suits. She currently handles a number of intellectual property and contract matters on behalf of artists and recording companies, and recently has served as outside general counsel for a media company.</p>`,
            `<p>As a <i>pro bono</i> advocate, Bridget has participated in the Innocence Project Seminar, seeking to reopen and reverse wrongful convictions based on DNA technology, and the Courtroom Advocates Project where she provided legal assistance to battered women. She has also represented adoptive parents in Juvenile Court.</p>`
          ],
          other: [
            {
              type: `Education:`,
              content: `Benjamin N. Cardozo School of Law (J.D. 2002; Symposia Editor, Cardozo Arts & Entertainment Law Journal); Syracuse University (B.S. Marketing 1995)`
            },
            {
              type: `Admitted:`,
              content: `California, New York, U.S. Court of Appeals for the Ninth Circuit`
            },
            {
              type: `Publications:`,
              content: `<div className="content-type"><i>Defending Mass Tort Claims: Get What You Paid For,</i> riskVue (Feb. 2005); <i>Put Your Money Where Your Mouth Is: Insurance Coverage Issues Arising from Food Contamination,</i> Coverage (July/Aug. 2009)</div>`
            }
          ]
        },
        {
          name: `Brian J. Wright`,
          title: `Of Counsel`,
          contact: `Direct: 213-988-6081`,
          email: `bwright@cooklawfirm.la`,
          img: imageThree,
          content: [
            `<span className="attorneys-text">Brian is a business litigator with more than twenty years of success in trial, arbitration, and appellate proceedings. He has had his own practice, has worked at a leading California appellate boutique firm, and has been a business litigator at a Los Angeles firm. He has a near-undefeated record on appeals and has several published decisions, including a Ninth Circuit decision on an important public policy matter. Before becoming a lawyer, he was an English teacher and counselor at local prep schools.<span>`
          ],
          other: [
            {
              type: `Education:`,
              content: `University of California, Los Angeles (J.D. 1993, Briefwriter for UCLA’s National Moot Court Team); Harvard University (A.B. cum laude, History & Literature 1983) `
            },
            {
              type: `Admitted:`,
              content: `California; all U.S. District Courts in California; U.S. Court of Appeals for the Ninth Circuit`
            },
            {
              type: `Publications:`,
              content: `<div className="content-type"><i>Defending Against Punitive Damage Claims</i> (L.A. Daily Journal). Brian also has participated in various MCLE presentations on litigation topics.<div>`
            }
          ]
        }
      ]
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="attorneys">
        <Navigation />
        <div className="container push-top">
          <div className="row">
            <aside className="col-md-3 side-bar-hidden attorneys-sidebar">
              <TinySlider />
            </aside>
            <div className="col-md-9 medium-screen-overwrite">
              <div className="row push-top-lg">
                <div>
                  {this.state.content.map((eachPerson, z) => (
                    <React.Fragment key={z}>
                      <div className="row push-bottom-lg">
                        <div className="col-md-3">
                          <div className="attorney-img-text-wrapper-mobile">
                            <img
                              className="attorney-imgs"
                              src={eachPerson.img}
                              alt={eachPerson.name}
                            />
                            <div className="mobile-view-attorneys">
                              <p className="bold">
                                {eachPerson.personalLink ? (
                                  <Link
                                    to={eachPerson.personalLink}
                                    className="name-link"
                                  >
                                    {eachPerson.name}
                                  </Link>
                                ) : (
                                  eachPerson.name
                                )}
                              </p>
                              <p className="bold">{eachPerson.title}</p>
                              <p className="contact">{eachPerson.contact}</p>
                              <a
                                className="mail-link"
                                href={`mailto:${eachPerson.email}?Subject=Cook%20Law%20Firm`}
                                target="_top"
                              >
                                {eachPerson.email}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="push-bottom">
                            {eachPerson.content.map((eachParagraph, i) => (
                              <React.Fragment key={i}>
                                {eachParagraph === `Read More ...` ? (
                                  <Link
                                    to={eachPerson.personalLink}
                                    className="read-more-link"
                                  >
                                    {eachParagraph}
                                  </Link>
                                ) : (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: eachParagraph
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            ))}
                          </div>

                          {eachPerson.other.map((eachOther, k) => (
                            <div key={k}>
                              <span className="bold-title">
                                <div
                                  className="content-type"
                                  dangerouslySetInnerHTML={{
                                    __html: eachOther.type
                                  }}
                                />
                              </span>{" "}
                              <div
                                className="attorneys-text"
                                dangerouslySetInnerHTML={{
                                  __html: eachOther.content
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
