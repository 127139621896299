import React, { Component } from "react";
import Slider from "react-slick";
import ACTA_Logo from "./ACTA_Logo.jpg";
import Chevron_Logo from "./Chevron_Logo.jpg";
import Experian_Logo from "./Experian_Logo.png";
import Hanson_Logo from "./Hanson_Logo.jpg";
import Lehman_Brothers_Logo from "./Lehman_Brothers_Logo.jpg";
import Metrolink_Logo from "./Metrolink_Logo.jpg";
import Ready_Pac_Logo from "./Ready_Pac_Logo.jpg";
import Tenet_Logo from "./Tenet_Logo.jpg";

import Xponential_Works_Logo from "./Xponential_Works_Logo.jpg";
import Weber_Metals_Logo from "./Weber_Metals_Logo.png";
import Public_Counsel_Logo from "./Public_Counsel_Logo.jpg";
import Lear_Capital_Logo from "./Lear_Capital_Logo.jpg";
import Hitachi_America_Logo from "./Hitachi_America_Logo.png";
import att_logo from "./AT&T_LOGO.png";

import "./representative-clients.css";
// import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./react-slicks-slider.css";

export default class ReactSlickSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div>
        <Slider {...settings}>
          <div className="item">
            <img alt={ACTA_Logo} src={ACTA_Logo} />
          </div>
          <div className="item">
            <img alt={att_logo} src={att_logo} />
          </div>
          <div className="item">
            <img alt={Chevron_Logo} src={Chevron_Logo} />
          </div>
          <div className="item">
            <img alt={Experian_Logo} src={Experian_Logo} />
          </div>
          <div className="item">
            <img alt={Hanson_Logo} src={Hanson_Logo} />
          </div>
          <div className="item">
            <img alt={Lehman_Brothers_Logo} src={Lehman_Brothers_Logo} />
          </div>
          <div className="item">
            <img alt={Metrolink_Logo} src={Metrolink_Logo} />
          </div>
          <div className="item">
            <img alt={Ready_Pac_Logo} src={Ready_Pac_Logo} />
          </div>
          <div className="item">
            <img alt={Tenet_Logo} src={Tenet_Logo} />
          </div>
          {/* NEW  */}
          <div className="item">
            <img alt={Xponential_Works_Logo} src={Xponential_Works_Logo} />
          </div>
          <div className="item">
            <img alt={Weber_Metals_Logo} src={Weber_Metals_Logo} />
          </div>
          <div className="item">
            <img alt={Public_Counsel_Logo} src={Public_Counsel_Logo} />
          </div>
          <div className="item">
            <img alt={Lear_Capital_Logo} src={Lear_Capital_Logo} />
          </div>
          <div className="item">
            <img alt={Hitachi_America_Logo} src={Hitachi_America_Logo} />
          </div>
        </Slider>
      </div>
    );
  }
}
