import React from "react";
import Navigation from "../navigation/navigation";
import TinySlider from "./tinySlider/tinySlider";
import "./firm.css";

export default class Firm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Why Us?",
      content: [
        "With our diverse practice experience, The Cook Law Firm distills the essentials of a case at the outset, develops a strategy that makes economic sense and is designed to optimize your position, and then concentrates our efforts to succeed. We are in the business of trying cases, putting skin in the game, and working tirelessly to achieve the best outcome possible.",
        "The Cook Law Firm was started by Los Angeles attorney Philip Cook after he spent two decades at one of the nation’s leading law firms. It is founded on the premise that, in a world of increasing specialization, the most effective and successful lawyers certainly master the law that applies to a dispute. But they bring the broader view of trial lawyers and counselors when handling different types of cases. Judges and juries who will decide your case are likely not experts in your industry, your business, or your products. A trial lawyer is an educator of the unfamiliar. A translator of the complex. Bringing your story to life, and making a compelling case for the courtroom decision-maker to see things your way.",
        "Before law school, Phil spent 12 years in the computer and consumer electronics fields, with business experience ranging from marketing and management positions with national Fortune 500 companies to his own entrepreneurial ventures. With this background, he is equally as comfortable in the boardroom or the CEO’s office as he is in the courtroom, and clearly communicates the essential risks and benefits of complex litigation so that business clients can act decisively.",
        "The Cook Law Firm provides a conflict-free alternative to big law firms for a wide array of plaintiffs and defendants. We have the perspective and skill set that corporate America expects from its attorneys, but deliver a more flexible, cost-effective, and responsive solution to litigation challenges.",
        "Attorneys at The Cook Law Firm work as a team. We get together and review our cases regularly, consider our progress, and decide next steps. We insist on diligent preparation, look for and develop viable exit strategies, and always are thinking about ways to streamline the case for trial. Because of this collaborative approach, supported by technology that allows us to work together in real-time, we move quickly, and each of us is ready to step up and execute.",
        "We enjoy the practice of law and are good at it. If you are a business that values exceptional-quality legal services and wants a novel, engaged approach to commercial litigation, we welcome the opportunity to work with you."
      ]
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="firm">
        <Navigation />
        <div className="container push-top-lg push-bottom-lg">
          <div className="row">
            <aside className="col-sm-3 side-bar-hidden">
              <TinySlider />
            </aside>
            <div className="col-sm-9">
              <h2 className="header push-bottom">{this.state.title}</h2>
              <div>
                {this.state.content.map((eachParagraph, i) => (
                  <p key={i}>{eachParagraph}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
