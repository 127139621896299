import React from "react";
import Navigation from "../navigation/navigation";
import TinySlider from "./tinySlider/tinySlider";
import "./practice.css";

export default class PracticeAreas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: `Practice Areas`,
      list: [
        `Antitrust & Trade Regulation`,
        `Business-to-Business Disputes`,
        `Consumer Class Actions`,
        `Corporate Governance`,
        `Data Breach and Privacy`,
        `First Amendment`,
        `Governmental Claims and Liabilities`,
        `Insurance Recovery`,
        `Intellectual Property Litigation`,
        [`Patent`, `Trademark`, `Copyright`, `Trade Secrets`],
        `Product Liability`,
        `Securities Litigation`
      ]
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="practice">
        <Navigation />
        <div className="container push-top-lg push-bottom-lg">
          <div className="row">
            <aside className="col-md-3 side-bar-hidden">
              <TinySlider />
            </aside>
            <div className="col-md-9">
              <h2 className="practice-header">{this.state.title}</h2>
              <ul className="practice-areas-ul push-top ">
                {this.state.list.map((each, index) => (
                  <React.Fragment key={index}>
                    <h2 className="approach-header">{each.sectionHeading}</h2>
                    {typeof each === "string" ? (
                      <li>{each}</li>
                    ) : (
                      <ul className="nested-ul">
                        {each.map((eachItem, i) => (
                          <li key={i}>{eachItem}</li>
                        ))}
                      </ul>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
