import React from "react";
import Navigation from "../../navigation/navigation";
import "./insurance-experience.css";
import { Link } from "react-router-dom";

export default class InsuranceExperience extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: `Philip Cook - Experience Highlights`,
      subTitle: `Securities / Corporate Governance`,
      content: [
        `<p>Represented a minority shareholder plaintiff in Abflex, exercising his shareholder inspection rights under Corporations Code section 1600, advising him concerning Board of Director participation, and filing suit alleging breach of fiduciary duties and theft of corporate opportunities against directors and majority shareholders. (<i>Yasutake v. Van der Hoeven</i>–LA Sup. Court, Cal. Court of Appeal)</p>`,
        `<p>Lead trial counsel for defendant PRC company <strong>Actions Semiconductor</strong> against claims by two former employees that stock options were granted as compensation, obtaining summary judgment, upheld on appeal. (<i>Young v. Actions Semiconductor Co., Ltd.</i>–S.D. Cal.; 9th Cir.)</p>`,
        `<p>Represented defendant <strong>Amway Asia Pacific</strong> and its officers and directors in four securities fraud class actions arising out of Amway Asia Pacific’s “going private” transaction. Lead counsel in similar California litigation involving <strong>Amway Japan</strong>, moving successfully to quash service of process for lack of personal jurisdiction over named-Japanese officers and directors. (<i>Wardrop v. Amway Asia Pacific, Ltd.</i>–S.D.N.Y and 2nd Cir.); <i>Chideler v. Amway Japan</i>–Monterey County Sup. Court)</p>`,
        `<p>Lead trial counsel for nominal defendant <strong>Chevron Corporation</strong> in a shareholder derivative action for breach of fiduciary duty and corporate waste after the company settled governmental investigations into alleged payment of illegal surcharges to Saddam Hussein contrary to the UN’s Oil For Food program. After alleging that demand on the Board would be futile, plaintiff stipulated to deem the complaint as a demand. A special litigation committee ultimately decided it would not be in the company’s best interest to pursue plaintiff’s claims. In a decision upheld on appeal, the court granted Chevron’s motion for judgment on the pleadings, denying plaintiff discovery, and deciding under Delaware law that plaintiff had failed to show that the Board committee failed to act independently, reasonably and in good faith. (<i>Bezirdjian v. O’Reilly</i>–Contra Costa County Sup. Court; Cal. Court of Appeal)</p>`,
        `<p>Lead trial counsel for intervenor <strong>Chevron Corporation</strong> in class action lawsuits filed by Unocal Corporation shareholders challenging a proposed $18 billion merger between Chevron and Unocal. Plaintiffs alleged that Unocal directors had breached their fiduciary duties by agreeing to merge with Chevron, despite a competing offer from Chinese oil company CNOOC. After successfully resisting discovery of Chevron’s internal evaluations and lobbying activity, the case settled with proxy revisions, and a subsequent shareholder vote approved the merger. (<i>Leib v. Unocal Corp.</i> and related actions–LA Sup. Court and C.D. Cal.)</p>`,
        `<p>First-chair trial counsel for plaintiff <strong>Echelon, LLC</strong> in a corporate governance and breach of fiduciary duty lawsuit under Delaware law, arising from the misappropriation of a commodities trading business. Obtained a 7-figure jury verdict after a 3-week trial, while successfully defending against cross-claims for business interruption and unfair competition. (<i>Echelon v. Dreiss</i>–San Francisco Sup. Court)</p>`,
        `<p>Lead trial counsel defending Taiwanese corporation <strong>GigaMedia</strong> against a claim by Microsoft that it was entitled to a $20 million “anti-dilution fee” pursuant to a stock warrant held by Microsoft, resolved by direct negotiations pre-litigation.</p>`,
        `<p>Lead trial counsel for PRC company <strong>IRICO Group Electronics Company</strong> defending a third-party complaint seeking indemnity for claims arising from IRICO’s initial public offering on Hong Kong’s Hang Seng Exchange, which were dismissed on summary judgment. (<i>BayStar Capital Management v. Core Pacific-Yamaichi Int’l</i>–C.D. Cal.)</p>`,
        `<p>Lead trial counsel for defendant <strong>Lehman Brothers Inc.</strong> in securities fraud action brought by late-stage institutional investors in Enron Corporation who had opted out of the Houston-venued nationwide class actions. (<i>Oaktree Capital Management v. Citibank</i>–LA Sup. Court)</p>`,
        `<p>First-chair trial counsel defending former employees of <strong>Lehman Brothers Inc.</strong> in a number of arbitration claims against various broker-dealer employees alleging losses sustained from investments in Auction Rate Securities (ARS) and subsequent market illiquidity. Obtained two Respondents’ awards after separate two-week hearings, defended a $140 million claim with a Claimant’s award of only $15 million after a 6-week hearing, and settled a number of others (and obtained expungement awards. (FINRA Arbitrations, Philadelphia, Houston and New York)</p>`,
        `<p>Lead trial counsel for the <strong>Public Utility District No. 1 of Snohomish County</strong>, Washington over the terms of a 30-year interest rate swap agreement. The swap agreement allowed the District to pay a fixed rate on the principal amount of a 1995 bond issue, while receiving from its counterparty the interest paid to investors on its variable rate demand obligations. Once the counterparty was required to make a net payment to the District, however, after receiving millions of dollars from the District during the first 13 years of the swap, the counterparty sought to terminate the swap agreement, declaring an event of default and filing suit that same day in New York state court. The District responded by filing suit and seeking a preliminary injunction in federal court in Seattle, simultaneously removing the New York action to federal court. After a series of forum skirmishes, and with trial then set to commence in Seattle, the matter settled. (<i>Public Utility Dist. No. 1 v. AIG Financial Prods. Corp. </i>–S.D.N.Y. and W.D. Wa., Seattle)</p>`,
        `<p>Lead trial counsel for plaintiff <strong>West Basin Municipal Water District</strong>, who sought rescission of an interest rate derivative swap transaction, disgorgement of fees earned by the defendants from those transactions, and fraud damages. Less than 2 years after the District entered into a swap transaction, an indictment was announced charging one of the District’s then-elected Board members with having taken an illegal bribe to promote and vote for the swap transaction. The Board member eventually pleaded guilty to extortion. The District obtained summary adjudication on the grounds that the bribe violated California’s conflict of interest statute, and the swap was ruled void ab initio under Gov. Code section 1092, and that the District had no further obligations under the swap (including any termination payments). (<i>West Basin Muni. Water Dist. v. Rice Fin. Prod.</i> –Ventura County Sup. Court)</p>`
      ]
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Navigation />
        <div id="insurance-experience">
          <div className="container push-top push-bottom-lg">
            <h2>{this.state.title}</h2>
            <hr />
            <p className="subtitle">{this.state.subTitle}</p>
            <ul>
              {this.state.content.map(each => (
                <li>
                  <div dangerouslySetInnerHTML={{ __html: each }} />
                </li>
              ))}
            </ul>
            <div className="extra-links">
              <div className="push-top-lg">
                <Link
                  to="/bio/insurance_experience_highlights/philip_cook"
                  className="Attorneys-Links"
                >
                  [Insurance Experience Highlights]
                </Link>
              </div>
              <div>
                <Link
                  to="/bio/other_experience_highlights/philip_cook"
                  className="Attorneys-Links"
                >
                  [Other Experience Highlights]
                </Link>
              </div>
              <div className="push-bottom-lg">
                <Link to="/Attorneys" className="Attorneys-Links">
                  [Back to Attorneys]
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
