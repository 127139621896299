import React from "react";
import Navigation from "../navigation/navigation";
import TinySlider from "./tinySlider/tinySlider";
import "./approach.css";
import philipcooksignature from "../assets/images/pcooksignature.png";

export default class Approach extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: `Our Approach`,
      subtitle: `Thoughts from the Founder and Managing Attorney`,
      content: [
        {
          sectionHeading: `Clients Come First`,
          paragraph: `Before starting The Cook Law Firm, I spent two decades with an international law firm that was consistently ranked at the top in client service by BTI Consulting. There, the mantra was: “Take care of the client, and everything else will take care of itself.” Embracing that notion, the attorneys at The Cook Law Firm never forget that we are working on your case. And because of that, we measure results only by considering your objectives, both in court and within the context of your business. The Firm takes the time to understand you and your business.`
        },
        {
          sectionHeading: `Commitment`,
          paragraph: `The Firm values the relationship we have with each of our clients, and brings the intensity of effort and resources required to win for you in the courtroom. At the same time, as cases move forward, the Firm provides you with the information you need to make informed decisions about litigation and settlement strategy, legal expense, and courtroom success.`
        },
        {
          sectionHeading: `Excellence`,
          paragraph: `My former firm was repeatedly ranked as member of the “Fearsome Foursome,” who general counsel and in-house decision makers "least want to see on the other side of the table." From that proud history, I make sure that the Firm’s attorneys have experience at the most rigorous levels of practice, that our work meets the highest standards, and I insist on personal accountability and integrity—with our clients, the courts, our adversaries, and among ourselves.`
        },
        {
          sectionHeading: `Trial`,
          paragraph: `Trial is the ultimate “object of the exercise” in litigation, and its outcome (or anticipated outcome) drives parties’ decisions. Cases rarely settle without a trial date on calendar, and often settle right before trial starts. The Firm begins each case developing trial themes, the law that will apply, and the evidence needed to win. In our view, a trial lawyer makes a difference. Whether in making a closing argument or maximizing your settlement position. When you hire a lawyer, ask yourself: “Are they ready to put jurors in the box and tell my story? Have they done it before? Have they won?” I am. And I have.`
        },
        {
          sectionHeading: `Mediation and Settlement`,
          paragraph: `Only a handful of cases ever go to trial. Most settle. I have always recommended to clients that we move cases forward on two fronts simultaneously—preparing for trial and negotiating a settlement. You should know what a negotiated outcome might look like early, and then reconsider how that might be changing as a case develops. And then you can decide whether the Firm pursues a litigated exit strategy or continues to prepare for trial.`
        },
        {
          sectionHeading: `Teamwork`,
          paragraph: `When I initially staff a case, attorneys are assigned because of their experience and what they can add to the case. But all of the Firm’s attorneys get together regularly to discuss all of our cases—where we are headed, what our obstacles might be, and what we need to do next to achieve success as efficiently and cost-effectively as possible.`
        },
        {
          sectionHeading: `Co-Counsel`,
          paragraph: `I have been fortunate to work with some exceptional, talented and creative lawyers over the years. Many now run their own successful law firms and specialize in certain types of cases. I do not hesitate proposing co-counsel from one of these firms (much like a large firm might staff partners and associates on a case) if necessary to achieve success. And when I do so, I actively manage litigation expense to make sure the Firm remains a cost-effective alternative.`
        },
        {
          sectionHeading: `Client "Partnerships"`,
          paragraph: `The greatest compliment you can pay the Firm is to retain us on another case. We handle each case with that objective in mind. Resolving your case efficiently and cost-effectively is our aim. And for those clients with whom the Firm regularly works, we look for ways to partner with you, whether through an Alternative Fee Arrangement (AFA), or other ways that help you accomplish your objectives.`
        },
        {
          sectionHeading: `Fees`,
          paragraph: [
            `The Firm is open to different fee structures, depending on the client and type of case. Our attorneys’ hourly rates are significantly lower than those billed at large firms for lawyers with similar backgrounds and experience. And our flexibility and lean staffing with experienced attorneys translates into greater efficiency.`,
            `We also will consider Alternative Fee Arrangements (AFAs) where that makes sense for you and the Firm. For recovery (plaintiff) work, AFAs could include contingency on any recovery (with costs paid as incurred) or a discounted or blended rate (with a reduced or fixed hourly fee and a reduced contingency on any recovery). For risk management (defense) work, AFAs could include fixed rates (for defined litigation activities). If you are interested in an AFA, let’s talk about it.`,
            `For clients who pay us an hourly fee, the Firm requires an advance evergreen retainer. Having an estimate of your monthly litigation expense in the Firm’s client trust account frees us to focus on what you need.`
          ]
        },
        {
          sectionHeading: `Budgets`,
          paragraph: `You are entitled to a litigation budget. But a budget is, at best, a thoughtful view of what might happen during litigation. And those things that affect litigation expenses, including opposing counsel and their clients, as well as judges and their handling of cases, cannot be predicted at the outset. No one can anticipate all of the activities required or the hours they may involve. So I make it a point to revisit case budgets regularly, looking back and looking forward, to help you keep track of where you are and where we are going.`
        }
      ],
      finalSentence: `We enjoy the practice of law and are good at it. If you need a lawyer to help you address your business litigation risk or pursue recovery, I look forward to connecting.`,
      name: `Philip Cook`,
      occupationalTitle: `Founder and Managing Attorney`,
      company: `The Cook Law Firm, P.C.`
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Navigation />
        <div id="approach" className="container push-top-lg push-bottom-lg">
          <div className="row">
            <aside className="col-md-3 side-bar-hidden">
              <TinySlider />
            </aside>
            <div className="col-md-9">
              <h3 className="approach-title push-bottom">{this.state.title}</h3>
              <h3 className="approach-subtitle push-bottom">
                {this.state.subtitle}
              </h3>
              {this.state.content.map((each, index) => (
                <React.Fragment key={index}>
                  <h3
                    className={
                      index === 0
                        ? `push-top approach-header push-bottom-sm`
                        : `push-top approach-header push-bottom`
                    }
                  >
                    {each.sectionHeading}
                  </h3>
                  {typeof each.paragraph === "string" ? (
                    <p>{each.paragraph}</p>
                  ) : (
                    <React.Fragment>
                      {each.paragraph.map((eachItem, i) => (
                        <p key={i}>{eachItem}</p>
                      ))}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
              <div className="approach-stars">
                <p className="stars">
                  *<span className="star-spacer">*</span>*
                </p>
              </div>
              <p>{this.state.finalSentence}</p>
              <img
                src={philipcooksignature}
                alt="signature"
                className="philipcook-signature"
              />
              <p>{this.state.name}</p>
              <p>{this.state.occupationalTitle}</p>
              <p>{this.state.company}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
