import React from "react";
import Navigation from "../navigation/navigation";
import TinySlider from "./tinySlider/tinySlider";
import "./representative-clients-assets/representative-clients.css";
import ReactSlickSlider from "./representative-clients-assets/react-slicks-slider";

export default class RepresentativeClients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: `Representative Clients`,
      list: [
        `3D Systems, Inc.`,
        `AT&T`,
        `Alameda Corridor Transportation Authority`,
        `Chevron Corporation`,
        `Experian Information Systems`,
        `Kaiser Cement and Gypsum Corporation`,
        `Lehman Brothers Inc.`,
        `Ready Pac Foods, Inc.`,
        `Southern California Regional Rail Authority / Metrolink`,
        `Tenet Healthcare Corporation`,
        `Textron, Inc.`,
        `Union Oil Company of California / Unocal Corporation`,
        `Hitachi America, Ltd.`,
        `Lear Capital, Inc.`,
        `Public Counsel`,
        `Weber Metals, Inc.`,
        `Xponential Works, Inc.`
      ]
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="representative-clients">
        <Navigation />
        <div className="container push-top-lg">
          <div className="row">
            <aside className="col-md-3 side-bar-hidden">
              <TinySlider />
            </aside>
            <div className="col-md-9">
              <h2 className="header">{this.state.title}</h2>
              <ul>
                {this.state.list.map((each, i) => (
                  <li key={i}>{each}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row-fluid">
            <div className="span12">
              <div className="header">
                <h2 className="title-bottom push-bottom">{this.state.title}</h2>
                <div className="row">
                  <div className="col-lg-12">
                    <ReactSlickSlider />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
