import React from "react";
import Navigation from "../../navigation/navigation";
import "./insurance-experience.css";
import { Link } from "react-router-dom";

export default class InsuranceExperience extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: `Philip Cook - Experience Highlights`,
      subTitle: `Insurance Recovery`
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Navigation />
        <div id="insurance-experience">
          <div className="container push-top push-bottom-lg">
            <h2>{this.state.title}</h2>
            <hr />
            <p className="subtitle">{this.state.subTitle}</p>

            <ul>
              <p>
                For <strong>Weber Metals</strong>, first-chair trial counsel in
                first-party coverage action against insurer under Boiler and
                Machinery Endorsement, obtaining a $44.4 million jury verdict
                and a post-trial award of $7.3 million in prejudgment interest
              </p>
              <p>
                For <strong>Partnership Placements</strong>, first-chair trial
                counsel in coverage and bad faith action against liability
                insurer for failure to defend, obtaining a $12.7 million jury
                verdict, including $10 million punitives
              </p>
              <p>
                For <strong>Ready Pac Foods</strong>, lead trial counsel in
                coverage action for bodily injury, property damage, and business
                interruption claims arising from an E. coli outbreak at Taco
                Bell stores
              </p>
              <p>
                For <strong>Experian Information Systems</strong>, lead trial
                counsel in coverage action against excess insurer for amounts
                paid to settle class action, including attorney fee award to
                class counsel
              </p>
              <p>
                For <strong>Northern Indiana Public Service Co.</strong>,
                first-chair trial counsel in coverage and bad faith action for
                liabilities at over 30 former manufactured gas plant sites,
                including litigation of fortuity; “occurrence” or “accident”;
                “owned property” exclusion; trigger; and pollution exclusions
              </p>
              <p>
                For <strong>B&G Development</strong>, first-chair trial counsel
                in coverage and bad faith action against Lloyd’s insurers under
                Employment Practices Liability policy for failure to defend,
                successfully resolving the case during jury deliberations after
                a 4-week trial
              </p>
              <p>
                For <strong>Kaiser Cement and Gypsum Corp.</strong>, first-chair
                trial counsel in coverage action involving primary and excess
                policies for asbestos exposure claims, including number of
                occurrences (146 Cal. App. 4th 648 (2007)); notice, tender and
                loss payable provisions; and “anti-stacking” of policy limits
                and “horizontal exhaustion” (215 Cal. App. 4th 210 (2013)), and
                a series of phased trials resolving deductible and allocation
                issues
              </p>
              <p>
                For Experian subsidiary <strong>ConsumerInfo.com</strong>, lead
                trial counsel in coverage action under successive claims-made
                policies for defense and indemnity of class action lawsuits
                challenging business and advertising methods, including issues
                involving claims-made policy periods and related claims
                exclusion
              </p>
              <p>
                For the{" "}
                <strong>Southern California Regional Rail Authority</strong> and
                the <strong>County of Los Angeles</strong>, lead appellate
                counsel after an arbitration award with different trial counsel
                resulted in denial of coverage for a Metrolink accident and a
                breach of contract damage award to the insurer
              </p>
              <p>
                Secured D&O coverage for securities-related claims, including
                Auction Rate Securities claims against former{" "}
                <strong>Lehman Brothers</strong> broker-dealer employees
              </p>
              <p>
                Advised multiple clients concerning rights to <i>Cumis</i>{" "}
                counsel under California Civil Code section 2860, including fee
                and conflict issues, monitoring of appointed or panel defense
                counsel, and analysis of tripartite relationship issues
              </p>
              <p>
                Advised insured concerning rights to defense and coverage under
                homeowner’s liability coverage
              </p>
              <p>
                Advised manufacturer concerning coverage under general liability
                and E&O policies for defective semiconductor product replacement
                and customer claims for consequential loss, including lost
                profits
              </p>
              <p>
                Advised insured concerning coverage under first-party property
                policies for multiple losses, including computer and data
                processing failures, manufacturing process contamination and
                structural failure
              </p>
            </ul>

            <div className="extra-links">
              <div className="push-top-lg">
                <Link
                  to="/bio/securities_corporate_governance/philip_cook"
                  className="Attorneys-Links"
                >
                  <h2 className="bottom-links">
                    <p className="bottom-links">
                      {" "}
                      [Corporate Governance / Securities Experience Highlights]
                    </p>
                  </h2>
                </Link>
              </div>
              <div>
                <Link
                  to="/bio/other_experience_highlights/philip_cook"
                  className="Attorneys-Links"
                >
                  <h2 className="bottom-links">
                    <p className="bottom-links">
                      [Other Experience Highlights]
                    </p>
                  </h2>
                </Link>
              </div>
              <div className="push-bottom-lg">
                <Link to="/Attorneys" className="Attorneys-Links">
                  <p className="bottom-links">[Back to Attorneys]</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
