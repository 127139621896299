import React from "react";
import Navigation from "../navigation/navigation";
import "./contact.css";

import axios from "axios";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.isDisabledButton = this.isDisabledButton.bind(this);

    this.state = {
      title: "Contact Us",
      firstName: "",
      email: "",
      message: "",
      contactTitle: `The Cook Law Firm`,
      contactInformation: [
        `601 S. Figueroa Street, Suite 2050`,
        `Los Angeles, CA 90017`,
        `Main: (213) 988-6100`,
        `Fax: (213) 988-6099`
      ],
      contactEmail: `info@cooklawfirm.la`,
      mailSent: false,
      error: false,
      isSendingEmail: false
    };
  }

  isDisabledButton() {
    const { email, message, firstName, isSendingEmail } = this.state;

    if (isSendingEmail) return true;

    return (
      /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) &&
      message.length > 0 &&
      firstName.length > 0
    );
    return false;
  }

  handleFormSubmit(event) {
    event.preventDefault();
    this.setState({ isSendingEmail: true });
    console.log(process.env.REACT_APP_SENDGRID_API_KEY);

    axios
      .post("http://localhost:5001/contact", {
        email: this.state.email,
        firstName: this.state.firstName,
        message: this.state.message
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({ mailSent: true, isSendingEmail: false });
        } else {
          // move to catch block
          throw "not successful";
        }
      })
      .catch(err =>
        this.setState({ isSendingEmail: false, mailSent: false, error: true })
      );
  }

  render() {
    let isDisabled = this.isDisabledButton();

    return (
      <div>
        <Navigation />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3305.7897494542317!2d-118.25709!3d34.049264!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c7b38aa9863b%3A0x422a7890eab38d0a!2sThe+Cook+Law+Firm!5e0!3m2!1sen!2sus!4v1429606480765"
          width="100%"
          height="400"
          frameBorder="0"
          title="cooklawfirmmap"
        />
        <div id="contact-us" className="container">
          <div className="row push-top-sm">
            <div className="col-md-8">
              <h2 className="page-title">{this.state.title}</h2>
              <div className="row-fluid">
                <div className="col-12">
                  <form id="cont-form">
                    <label>Your Name</label>
                    <input
                      className="contact-form-input"
                      placeholder="Your Name"
                      id="firstName"
                      name="firstName"
                      type="text"
                      value={this.state.firstName}
                      onChange={e =>
                        this.setState({ firstName: e.target.value })
                      }
                    />
                    <label>Email Address</label>
                    <input
                      placeholder="Email"
                      className="contact-form-input"
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                    <label>Message</label>
                    <textarea
                      placeholder="Content"
                      className="contact-form-input"
                      id="message"
                      name="message"
                      value={this.state.message}
                      onChange={e => this.setState({ message: e.target.value })}
                    />
                    <span
                      className={
                        this.state.mailSent || this.state.error ? "hidden" : ""
                      }
                    >
                      {!this.state.mailSent && (
                        <button
                          type="submit"
                          className="submit-form"
                          onClick={e => this.handleFormSubmit(e)}
                          disabled={!isDisabled}
                        >
                          Submit
                        </button>
                      )}
                    </span>
                  </form>

                  {this.state.isSendingEmail && (
                    <div style={{ marginBottom: "20px" }}>
                      <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}

                  <div
                    className={
                      !this.state.mailSent ? "hidden" : "successMessage"
                    }
                  >
                    <p>
                      Thank you for contacting The Cook Law Firm. We will get
                      back to you as soon as possible.
                    </p>
                  </div>
                  <div className={!this.state.error ? "hidden" : ""}>
                    <p>
                      Oh no! We ran into a snag. Please refresh the page and
                      submit the form again.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 push-bottom">
              <div>
                <hr className="gray-line" />
              </div>
              <h2 className="contact-title">{this.state.contactTitle}</h2>
              {this.state.contactInformation.map((each, i) => (
                <p key={i} className="contact-information">
                  {each}
                </p>
              ))}
              <a
                className="email-link"
                href="mailto:info@cooklawfirm.la?Subject=Cook%20Law%20Firm"
                target="_top"
              >
                {this.state.contactEmail}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
