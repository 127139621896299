import React from "react";
import Navigation from "../navigation/navigation";
import { Link, Redirect } from "react-router-dom";
import TinySliderHome from "./tinySlider/tinySliderHome";
import "./home.css";

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldRedirectToTab: (window.location.host === 'cookmediation.com' || window.location.host === 'cookmediation.la'),
      content: [
        {
          heading: "Why Us?",
          copy:
            "As trial lawyers with broad experience and perspective in different types of cases, we understand and make accessible to judges and juries your industry, your business and your products. Trial lawyers bring your story to life, and make a compelling case for the courtroom decision-maker to see things from your perspective. And improving the outcome, whether in settlement or before a jury.",
          url: "The-Firm",
          isLink: true
        },

        {
          heading: "Our Approach",
          copy:
            "Understanding, commitment and excellence. Embracing these fundamental principles, we first distill the essentials of a case. After developing an informed strategy, we then concentrate our efforts to succeed. Whether at trial, through an early exit strategy, or to consider settlement opportunities optimized by our efforts, your position is enhanced by engaging lawyers who love the practice of law and are good at it.",
          url: "Approach",
          isLink: true
        },
        {
          heading: "Who Are We?",
          copy: [
            "We attract and work with engaged and appreciative clients",
            "We solve their legal problems efficiently and cost-effectively",
            "We earn their trust, their repeat business, and their heartfelt endorsements"
          ],
          url: "",
          isLink: false
        }
      ]
    };
  }
  render() {
    if(this.state.shouldRedirectToTab) { return <Redirect to={'/mediation'}/>; };
    return (
      <div>
        <Navigation />
        <div id="slider-wrapper">
          <TinySliderHome />
        </div>
        <div
          className="container"
          id="home-body"
          className="push-top push-bottom"
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="container">
                <div className="row justify-content-md-center">
                  {this.state.content.map((eachParagraph, index) => (
                    <div className="col-lg-4" key={index}>
                      <div className="each-section-wrapper">
                        <h2>
                          <Link
                            to={eachParagraph.url}
                            href={eachParagraph.url}
                            className={
                              eachParagraph.isLink
                                ? "home-link"
                                : "home-heading"
                            }
                          >
                            {eachParagraph.heading}
                          </Link>
                        </h2>
                        <hr className="redline" />
                        <div>
                          {typeof eachParagraph.copy === "string" ? (
                            <p>
                              {eachParagraph.copy}{" "}
                              <Link
                                to={eachParagraph.url}
                                className="home-read-more-link"
                              >
                                Read More...
                              </Link>
                            </p>
                          ) : (
                            <ul>
                              {eachParagraph.copy.map((eachItem, i) => (
                                <li key={i} className="home-bullets">
                                  {eachItem}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
