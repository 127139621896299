import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: [
        "The Cook Law Firm",
        "601 S. Figueroa Street, Suite 2050",
        "Los Angeles, CA 90017",
        "213-988-6100"
      ]
    };
  }
  render() {
    const addressLength = this.state.address.length - 1;

    return (
      <div id="footer-bottom">
        <div id="copyright">
          <div className="container">
            <div className="row-fluid">
              <div className="col-lg-12">
                {this.state.address.map(function(each, index) {
                  return (
                    <React.Fragment key={index}>
                      <span>{each}</span>
                      <span className="bar">
                        {index !== addressLength ? "|" : ""}
                      </span>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            <div className="page-footer">
              <div className="row-fluid">
                <div className="col-lg-12">
                  <span>
                    <Link to="/terms-of-use" href="/terms-of-use">
                      Terms of Use
                    </Link>
                  </span>
                  <span className="bar">|</span>
                  <span>
                    <Link to="/privacy-policy" href="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </span>
                  <span className="bar">|</span>
                  <span>
                    © 2020 The Cook Law Firm, P.C. All Rights Reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
