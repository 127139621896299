import React from "react";
import Navigation from "../../navigation/navigation";
import "./insurance-experience.css";
import { Link } from "react-router-dom";

export default class InsuranceExperience extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: `Philip Cook - Experience Highlights`,
      content: [
        {
          subtitle: `Intellectual Property / Trade Secrets`,
          content: [
            `<p>First-chair trial counsel for claimant <strong>3D Systems, Inc.</strong> in a breach of contract and trade secret dispute between joint developers of specialty photocurable polymers used in rapid prototyping stereolithography systems. Settled favorably after two days of trial, including cross-examination of two chemists, who were the principal witnesses for defendant. (<i>3D Sys., Inc. v. Vantico Int’l, S.A.</i>. (ICC Arbitration, New York)</p>`,
            `<p>First-chair trial counsel for defendants <strong>University of Texas</strong> and its licensee, 3D Systems, in a patent infringement action relating to materials used in solid free-form fabrication. Settled favorably after technology tutorial and claims construction during a pre-trial Markman hearing. (<i>Board of Regents, Univ. of Texas v. EOS GmbH Electro Optical Sys.</i> –W.D. Tex., Austin)</p>`,
            `<p>First-chair trial counsel for <strong>3D Systems</strong> and its subsidiary <strong>DTM Corporation</strong> defending against patent infringement claims on client’s own patents, asserted by client’s licensee against a corporation who developed selective laser sintering and client had acquired, together with one of the acquired company’s customers (<i>EOS GmbH Electro Optical Sys. v. DTM Corp.</i>–C.D. Cal.)</p>`
          ]
        },
        {
          subtitle: `Antitrust / Trade Regulation`,
          content: [
            `<p>First-chair trial counsel defending <strong>Union Oil Company of California</strong> and its parent corporations, <strong>Unocal Corporation</strong> and <strong>Chevron Corporation</strong>, in 14 federal (MDL) and state court consumer class actions, all mirroring a 2003 FTC administrative complaint (FTC Docket No. 9305). Plaintiffs alleged misconduct arising from Union Oil’s lobbying activity before the California Air Resources Board (CARB) during 1991 rulemaking for low-emission or reformulated gasoline (RFG) standards. Plaintiffs claimed that Union Oil wrongfully failed to disclose to the CARB that it was prosecuting a patent related to low-emissions gasoline at the time it advocated certain fuel standards. The U.S. Patent Office eventually issued 5 separate RFG patents to Union Oil, the first of which Union Oil successfully litigated to a jury verdict in 1997 (and defended successfully in a published CAFC decision). Although the 5 patents later were terminally dedicated and deemed unenforceable in order to obtain the FTC’s approval of Chevron’s acquisition of Unocal in 2005 (which also settled the FTC’s administrative complaint), plaintiffs sought over $10 billion in damages and restitution for alleged RFG price increases during the class period and amounts received by Union Oil from enforcing its first RFG patent. The case settled for $48 million, most of which was paid into a cy pres fund. (<i>In re Reformulated Gasoline (RFG) Antitrust & Patent Litig. –C.D. Cal.; Lossada v. Union Oil Co. of Calif.</i>–LA Sup. Court)</p>`
          ]
        },
        {
          subtitle: `Products Liability / Toxic Tort`,
          content: [
            `<p>Lead trial counsel defending product liability claims against a respirator manufacturer (and its successors in interest, including <strong>Textron Inc.</strong>, <strong>Norton Company</strong>, and <strong>St. Gobain</strong>) brought by over 50 plaintiffs who had worked at diatomaceous earth mines in Lompoc, California and alleged respiratory disease, including mixed dust pneumoconeosis, from inhalation of silica-based particles. Removed to federal court and obtained summary judgment against majority of plaintiffs, resolving the remainder by settlement. (<i>Stewart v. Mine Safety Appliances</i> Co.-Santa Barbara County Sup. Court; C.D. Cal.)</p>`
          ]
        },
        {
          subtitle: `Health Care`,
          content: [
            `<p>Lead trial counsel for claimant <strong>Tenet Healthcare Corporation</strong> seeking $100 million in unpaid reimbursement to over twenty Tenet hospitals in California that had provided medical treatment to Health Net health plan members, while concurrently defending cross-claims. (<i>Doctors Med. Ctr. Modesto v. Health Net, Inc.</i>–AAA Arbitration)</p>`
          ]
        },
        {
          subtitle: `Governmental Claims and Liabilities`,
          content: [
            `<p>First-chair trial counsel for <strong>Alameda Corridor Transportation Authority</strong> (ACTA) After a two-week trial, obtained injunction against City of Vernon from further interference with construction of the $2.4 billion Alameda Corridor Project, a consolidated rail corridor from the ports of Los Angeles and Long Beach to downtown Los Angeles, while defending successfully against Vernon’s attempt to enjoin construction within city limits. (<i>City of Vernon v. Alameda Corridor Transp. Auth.</i>–LA Sup. Court; CCP § 639 reference)</p>`,
            `<p>Lead trial counsel for the <strong>County of Los Angeles</strong> in three employment class actions, each of which alleged that independent contractors, or employees of contractors with the County, met the test for common law employment and thus were entitled to civil service status, pay, protections, benefits and County pensions. All were dismissed through summary adjudication motions, following disqualification of class counsel, affirmed on appeal. (<i>Holmgren v. County of Los Angeles, Shiell v. County of Los Angeles, Hall v. County of Los Angeles-LA Sup. Court</i>; Cal. Court of Appeal)</p>`,
            `<p>Defended the <strong>County of Los Angeles</strong> against claims brought by an architect who had provided services to a developer on a design-build project for a new County courthouse in Lancaster, California. Obtained summary judgment, which was affirmed on by the Court of Appeal. (<i>Kudrave v. County of Los Angeles</i>-LA Sup. Court, Cal. Court of Appeal)</p>`,
            `<p>Appellate counsel for the <strong>Southern California Regional Rail Authority</strong> after an arbitration award denying Metrolink insurance coverage for claims arising from an accident and awarding the insurer judgment for breach of contract. The case was resolved successfully in mediation after briefing and before oral argument or decision. (<i>Zurich Ins. Co. v. So. Calif. Reg. Rail Auth. dba Metrolink</i>-Cal. Court of Appeal)</p>`
          ]
        }
      ]
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Navigation />
        <div id="insurance-experience">
          <div className="container push-top push-bottom-lg">
            <h2>{this.state.title}</h2>
            <hr />
            <p className="subtitle">{this.state.subTitle}</p>

            {this.state.content.map(each => (
              <React.Fragment>
                <p className="push-bottom">{each.subtitle}</p>
                <ul>
                  {each.content.map(eachRow => (
                    <li>
                      <div dangerouslySetInnerHTML={{ __html: eachRow }} />
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}

            <div className="extra-links">
              <div className="push-top-lg">
                <Link
                  to="/bio/Insurance_Experience_Highlights/philip_cook"
                  className="Attorneys-Links"
                >
                  <h2 className="bottom-links">
                    [Insurance Experience Highlights]
                  </h2>
                </Link>
              </div>
              <div>
                <Link
                  to="/bio/securities_corporate_governance/philip_cook"
                  className="Attorneys-Links"
                >
                  <h2 className="bottom-links">
                    [Corporate Governance / Securities Experience Highlights]
                  </h2>
                </Link>
              </div>
              <div className="push-bottom-lg">
                <Link to="/Attorneys" className="Attorneys-Links">
                  <h2 className="bottom-links">[Back to Attorneys]</h2>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
