import React from "react";
import Navigation from "../navigation/navigation";
import CookMediation from "./mediation-assets/COOK_Mediation_Logo_v1A.png";
import PhilipCookImg from "../../src/assets/images/attorneys/p_cook.jpg";
import BookingAgreement from "./mediation-assets/Booking_Agreement.pdf";
import ConfidentialityAgreement from "./mediation-assets/Confidentiality_Agreement.pdf";
import VirtualZoomMeeting from "./mediation-assets/Virtual_Zoom_Meeting.pdf";
import VirtualMediationGuidelinesAgreement from "./mediation-assets/Virtual_Mediation_Guidelines_Agreement.pdf";
import "./mediation-assets/mediation.css";

export default class Mediation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paragraph: `Philip Cook’s experience as a private mediator and court-appointed settlement officer, mediator and neutral evaluator gives him perspective on your dispute.  His success as a multi-state trial lawyer helps him understand the parties’ positions and the alternatives to settlement. Whether your case is a dispute between individuals or among the nation’s largest businesses, Phil has the background and experience to understand the process, to proactively explore settlement options and to help guide the parties to a workable, enforceable outcome.`,
      subTitle: `Types of disputes`,
      textList: [
        `Patent, Copyright and Trademark`,
        `Insurance Policies, Coverage and Bad Faith`,
        `Employment, Discrimination and Civil Rights`,
        `Contract / Business Disputes`,
        `Consumer Claims / Products Liability`,
        `Corporate Governance, Partnerships and Securities Litigation`
      ],
      subTitleTwo: `Visit our website`
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="mediation">
        <Navigation />
        <div
          className="container push-top-lg push-bottom-lg"
          style={{ zIndex: 2, position: "relative", marginTop: "85px" }}
        >
          <div className="row">
            <aside className="col-md-3">
              <div className="mobile-only">
                <a
                  href={"https://www.cookmediation.com/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={CookMediation}
                    alt="Cook Mediation"
                    className="cookMediationLogo mobile-mediation-img"
                  />
                </a>
              </div>
              <div className="pdf-wrapper">
                <div className="block img-wrapper">
                  <img
                    src={PhilipCookImg}
                    alt="Philip Cook"
                    className="PhilipCookLogo"
                  />
                </div>
                <div className="PDF-text-wrapper">
                  <a
                    className="PDFDownloadsClass"
                    href={VirtualZoomMeeting}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-file-pdf-o" aria-hidden="true" />
                    <p>Participating in a Virtual Mediation</p>
                  </a>
                </div>
              </div>
            </aside>
            <div className="col-md-9 reset-space">
              <a
                href={"https://www.cookmediation.com/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={CookMediation}
                  alt="Cook Mediation"
                  className="cookMediationLogo push-bottom-sm"
                />
              </a>
              <p>{this.state.paragraph}</p>
              <h2 className="push-bottom-sm">{this.state.subTitle}</h2>
              <p>
                {`Phil has litigated a `}
                <a
                  href="/bio/philip_cook"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textDecoration: "none"
                  }}
                >
                  wide range of disputes
                </a>{" "}
                {`and invites you to discuss with him any case you believe might be suitable for mediation, including individual or class action employment litigation. Recently, Phil has been focusing on the following types of matters:`}
              </p>
              <ul className="mediation-ul">
                {this.state.textList.map((each, i) => (
                  <li key={i}>{each}</li>
                ))}
              </ul>
              <h2 className="push-bottom-sm">
                {" "}
                <a
                  href="https://www.cookmediation.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "rgb(47, 45, 45)" }}
                >
                  {this.state.subTitleTwo}
                </a>
              </h2>
              <p>
                At the{" "}
                <a
                  href="https://www.cookmediation.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "bold"
                  }}
                >
                  Cook Mediation website
                </a>
                , you will find News & Insights, as well as a number of Guides &
                Resources, including information and tips about participating in
                a remote or “virtual” mediation through Zoom.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
