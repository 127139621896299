import React from "react";
import Navigation from "../navigation/navigation";
import Footer from "../footer/footer";

export default class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: `Privacy Policy`,
      paragraph: `Your privacy is very important to The Cook Law Firm. We have developed this Privacy Policy so you can understand how we collect, communicate, disclose, and make use of personal information:`,
      list: [
        `Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.`,
        `We will collect and use personal information solely with the objective of fulfilling those purposes specified by us and for other compatible purposes, unless we obtain the consent of the individual concerned or as required by law.`,
        `We will collect personal information by lawful and fair means and, where appropriate, with the knowledge and/or consent of the individual concerned.`,
        `We will retain personal information only as long as necessary for the fulfillment of those purposes.`,
        `We believe that personal data should be relevant to the purposes for which it is to be used and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.`,
        `No data transmission over the Internet can be guaranteed to be 100 percent secure. Although we strive to protect your personal information, The Cook Law Firm cannot ensure or warrant the security of any information you transmit to us or any information provided online, and you do so at your own risk. We will not be liable for disclosures of your personal information due to errors in transmission or unauthorized acts of third parties.`,
        `Once we receive your transmission, we will do our best to ensure its security on our systems by making reasonable efforts to protect the information against loss or theft, as well as unauthorized access, disclosure, copying, use, or modification.`,
        `The Cook Law Firm is committed to building a trusting relationship with you We will make readily available to clients and prospective clients information about our policies and practices relating to the management of personal information. We also will continually monitor feedback and seek to improve our services to meet your needs.`
      ],
      endingParagraph: `We are committed to conducting business at The Cook Law Firm in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained. If you have any questions about this Privacy Policy or the practices of this web site, or if you would like to provide comments to us about these things, please feel free to contact us.`
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Navigation />
        <div className="container push-top push-bottom">
          <div className="row">
            <h1 className="tos-pp-header push-top push-bottom">
              {this.state.title}
            </h1>
            <p className="tos-pp-normal">{this.state.paragraph}</p>
            <ul>
              {this.state.list.map(each => (
                <li className="tos-pp-normal">{each}</li>
              ))}
            </ul>
            <p className="tos-pp-normal">{this.state.endingParagraph}</p>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
