import React from "react";
import TinySlider from "tiny-slider-react";
import QuoteOne from "./quotes/quoteone.png";
import QuoteTwo from "./quotes/quotetwo.png";
import QuoteThree from "./quotes/quotethree.png";
import QuoteFour from "./quotes/quotefour.png";
import QuoteFive from "./quotes/quotefive.png";
import QuoteSix from "./quotes/quotesix.png";
import QuoteSeven from "./quotes/quoteseven.png";
import QuoteEight from "./quotes/quoteeight.png";
import "./tinySlider.css";

export default class tinySlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  // https://github.com/ganlanyuan/tiny-slider/issues/9
  render() {
    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center"
    };

    const imgStyles = {
      width: "100%",
      height: "auto",
      objectFit: "cover"
    };

    const imgs = [
      QuoteOne,
      QuoteTwo,
      QuoteThree,
      QuoteFour,
      QuoteFive,
      QuoteSix,
      QuoteSeven,
      QuoteEight
    ];

    const loadingImage =
      "data:image/gif;base64,R0lGODlhAQABAPAAAMzMzAAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";

    //   "data:image/gif;base64,\
    // R0lGODlhAQABAPAAAMzMzAAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";

    const settings = {
      lazyload: true,
      nav: false,
      mouseDrag: true,
      loop: true,
      items: 1,
      gutter: 0,
      autoplay: true,
      speed: 1500,
      controls: false,
      autoplayButtonOutput: false,
      responsive: {
        420: {
          items: 1
        }
      }
    };

    return (
      <div style={styles} className="tinySliderQuotes">
        <TinySlider settings={settings}>
          {imgs.map((el, index) => (
            <div key={index} style={{ position: "relative" }}>
              <img
                className={`tns-lazy-img`}
                src={loadingImage}
                data-src={el}
                alt={el.replace("/static/media/", "").split(".")[0]}
                style={imgStyles}
              />
            </div>
          ))}
        </TinySlider>
      </div>
    );
  }
}
